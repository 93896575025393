import animate_rect_mp4 from "../../../../content/blog/bots2d-simulator/animate_rect.mp4";
import animate_rect_webm from "../../../../content/blog/bots2d-simulator/animate_rect.webm";
import animate_wheel_mp4 from "../../../../content/blog/bots2d-simulator/animate_wheel.mp4";
import animate_wheel_webm from "../../../../content/blog/bots2d-simulator/animate_wheel.webm";
import both_sensor_mp4 from "../../../../content/blog/bots2d-simulator/both_sensor.mp4";
import both_sensor_webm from "../../../../content/blog/bots2d-simulator/both_sensor.webm";
import camera_movement_mp4 from "../../../../content/blog/bots2d-simulator/camera_movement.mp4";
import camera_movement_webm from "../../../../content/blog/bots2d-simulator/camera_movement.webm";
import keyboard_control_mp4 from "../../../../content/blog/bots2d-simulator/keyboard_control.mp4";
import keyboard_control_webm from "../../../../content/blog/bots2d-simulator/keyboard_control.webm";
import line_detector_mp4 from "../../../../content/blog/bots2d-simulator/line_detector.mp4";
import line_detector_webm from "../../../../content/blog/bots2d-simulator/line_detector.webm";
import range_sensor_mp4 from "../../../../content/blog/bots2d-simulator/range_sensor.mp4";
import range_sensor_webm from "../../../../content/blog/bots2d-simulator/range_sensor.webm";
import rendering_and_physics_mp4 from "../../../../content/blog/bots2d-simulator/rendering_and_physics.mp4";
import rendering_and_physics_webm from "../../../../content/blog/bots2d-simulator/rendering_and_physics.webm";
import robot_rotation_mp4 from "../../../../content/blog/bots2d-simulator/robot_rotation.mp4";
import robot_rotation_webm from "../../../../content/blog/bots2d-simulator/robot_rotation.webm";
import sumobot_testscene_mp4 from "../../../../content/blog/bots2d-simulator/sumobot_testscene.mp4";
import sumobot_testscene_webm from "../../../../content/blog/bots2d-simulator/sumobot_testscene.webm";
import wheel_motor_friction_mp4 from "../../../../content/blog/bots2d-simulator/wheel_motor_friction.mp4";
import wheel_motor_friction_webm from "../../../../content/blog/bots2d-simulator/wheel_motor_friction.webm";
import * as React from 'react';
export default {
  animate_rect_mp4,
  animate_rect_webm,
  animate_wheel_mp4,
  animate_wheel_webm,
  both_sensor_mp4,
  both_sensor_webm,
  camera_movement_mp4,
  camera_movement_webm,
  keyboard_control_mp4,
  keyboard_control_webm,
  line_detector_mp4,
  line_detector_webm,
  range_sensor_mp4,
  range_sensor_webm,
  rendering_and_physics_mp4,
  rendering_and_physics_webm,
  robot_rotation_mp4,
  robot_rotation_webm,
  sumobot_testscene_mp4,
  sumobot_testscene_webm,
  wheel_motor_friction_mp4,
  wheel_motor_friction_webm,
  React
};