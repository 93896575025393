import testing_led_mp4 from "../../../../content/projects/sumobot/testing_led.mp4";
import testing_led_webm from "../../../../content/projects/sumobot/testing_led.webm";
import four_motors_driving_mp4 from "../../../../content/projects/sumobot/four_motors_driving.mp4";
import four_motors_driving_webm from "../../../../content/projects/sumobot/four_motors_driving.webm";
import paint_it_black_mp4 from "../../../../content/projects/sumobot/paint_it_black.mp4";
import paint_it_black_webm from "../../../../content/projects/sumobot/paint_it_black.webm";
import robot_controlled_mp4 from "../../../../content/projects/sumobot/robot_controlled.mp4";
import robot_controlled_webm from "../../../../content/projects/sumobot/robot_controlled.webm";
import robot_is_born_mp4 from "../../../../content/projects/sumobot/robot_is_born.mp4";
import robot_is_born_webm from "../../../../content/projects/sumobot/robot_is_born.webm";
import robot_spin_fast_mp4 from "../../../../content/projects/sumobot/robot_spin_fast.mp4";
import robot_spin_fast_webm from "../../../../content/projects/sumobot/robot_spin_fast.webm";
import * as React from 'react';
export default {
  testing_led_mp4,
  testing_led_webm,
  four_motors_driving_mp4,
  four_motors_driving_webm,
  paint_it_black_mp4,
  paint_it_black_webm,
  robot_controlled_mp4,
  robot_controlled_webm,
  robot_is_born_mp4,
  robot_is_born_webm,
  robot_spin_fast_mp4,
  robot_spin_fast_webm,
  React
};