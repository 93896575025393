import linefollower_mp4 from "../../../../content/projects/bots2d/linefollower.mp4";
import linefollower_webm from "../../../../content/projects/bots2d/linefollower.webm";
import sumobot_testscene2_mp4 from "../../../../content/projects/bots2d/sumobot_testscene2.mp4";
import sumobot_testscene2_webm from "../../../../content/projects/bots2d/sumobot_testscene2.webm";
import * as React from 'react';
export default {
  linefollower_mp4,
  linefollower_webm,
  sumobot_testscene2_mp4,
  sumobot_testscene2_webm,
  React
};