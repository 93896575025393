import lsp_example_mp4 from "../../../../content/pages/lsp_example.mp4";
import lsp_example_webm from "../../../../content/pages/lsp_example.webm";
import fzf_example_mp4 from "../../../../content/pages/fzf_example.mp4";
import fzf_example_webm from "../../../../content/pages/fzf_example.webm";
import * as React from 'react';
export default {
  lsp_example_mp4,
  lsp_example_webm,
  fzf_example_mp4,
  fzf_example_webm,
  React
};