// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-devenv-js": () => import("./../../../src/pages/devenv.js" /* webpackChunkName: "component---src-pages-devenv-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-all-posts-js": () => import("./../../../src/templates/all-posts.js" /* webpackChunkName: "component---src-templates-all-posts-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-project-about-js": () => import("./../../../src/templates/project-about.js" /* webpackChunkName: "component---src-templates-project-about-js" */)
}

